export default class EventNames {

    public static get Refresh() { return "ihost.pages.refresh"; }
    public static get ShowConfirm() { return "ihost.pages.showConfirm"; }
    public static get ShowInfo() { return "ihost.pages.showInfo"; }
    public static get ShowError() { return "ihost.pages.showError"; }
    public static get ShowWarning() { return "ihost.pages.showWarning"; }
    public static get CustomerSelected() { return "ihost.components.customer-select-dropdown.customer-selected"; }
    public static get UserSelected() { return "ihost.components.user-select-dropdown.user-selected"; }
    public static get LegacyIFrameLoaded() { return "ihost.pages.legacyiframe.loaded"; }
    public static get ViewShown() { return "ihost.pages.view_shown"; }

    public static RtuNotes = {
        get Pinned() { return "ihost.pages.rtu_notes.pinned"; },
        get Edit() { return "ihost.pages.rtu_notes.edit"; },
        get SaveChanges() { return "ihost.pages.rtu_notes.save"; },
        get CancelEdit() { return "ihost.pages.rtu_notes.cancel"; },
        get Deleted() { return "ihost.pages.rtu_notes.deleted"; },
        get Revisions() { return "ihost.pages.rtu_notes.revisions"; }
    };

    public static CertificateManagement = {
        get DeleteCertificate() { return "ihost.pages.certificate_management.delete_certificate"; },
        get GenerateCertificate() { return "ihost.pages.certificate_management.generate_certificate"; },
        get UploadCertificate() { return "ihost.pages.certificate_management.upload_certificate"; },
        get UpdateRtuCertificateStatus() { return "ihost.pages.certificate_management.update_rtu_certificate_status"; }
    }

    public static CustomerIconSetCreateEdit = {
        get IconSelected() { return "ihost.pages.customer_icon_set_create_edit.icon_selected"; },
        get IconImageSelected() { return "ihost.pages.customer_icon_set_create_edit.icon_image_selected"; },
        get ColourSelected() { return "ihost.pages.customer_icon_set_create_edit.colour_selected"; },
        get FlashingChecked() { return "ihost.pages.customer_icon_set_create_edit.flashing_checked"; }
    }

    public static CustomerIconSets = {
        get Copy() { return "ihost.pages.customer_icon_sets.copy"; },
        get Deleted() { return "ihost.pages.customer_icon_sets.deleted"; },
        get IconSetSelected() { return "ihost.pages.customer_icon_sets.icon_set_selected"; }
    }

    public static MapView = {
        get FilterSelected() { return "ihost.pages.map_view.filter_selected"; },
        get MappingProviderSelected() { return "ihost.pages.map_view.mapping_provider_selected"; },
        get MappingCultureSelected() { return "ihost.pages.map_view.mapping_culture_selected"; },
        get ImagerySetSelected() { return "ihost.pages.map_view.imagery_set_selected"; },
        get MapLabelPositionSelected() { return "ihost.pages.map_view.map_label_position_selected"; },
        get ShowPointIconLabelsChecked() { return "ihost.pages.map_view.show_point_icon_labels_checked"; },
        get ShowRtuIconLabelsChecked() { return "ihost.pages.map_view.show_rtu_icon_labels_checked"; },
        get OverlayZoomClicked() { return "ihost.pages.map_view.overlay_zoom_clicked"; },
        get OverlayLocateInTreeClicked() { return "ihost.pages.map_view.overlay_locate_in_tree_clicked"; },
        get OverlayCloseClicked() { return "ihost.pages.map_view.overlay_close_clicked"; },
        get ExpandClicked() { return "ihost.pages.map_view.expand_clicked"; }
    }

    public static SingleLineDiagramView = {
        get NetworkSelected() { return "ihost.pages.single_line_diagram_view.network_selected"; },
        get ShowIconLabelsChecked() { return "ihost.pages.single_line_diagram_view.show_icon_labels_checked"; },
        get ShowMousePositionChecked() { return "ihost.pages.single_line_diagram_view.show_mouse_position_checked"; },
        get ShowZoomLevelChecked() { return "ihost.pages.single_line_diagram_view.show_zoom_level_checked"; },
        get DeclutterNodes() { return "ihost.pages.single_line_diagram_view.declutter_nodes"; },
        get LabelPositionSelected() { return "ihost.pages.single_line_diagram_view.label_position_selected"; },
        get FilterSelected() { return "ihost.pages.single_line_diagram_view.filter_selected"; },
        get ExpandClicked() { return "ihost.pages.single_line_diagram_view.expand_clicked"; },
        get NetworkEdited() { return "ihost.pages.single_line_diagram_view.network_edited"; },
        get NetworkEditableAreaSizeChanged() { return "ihost.pages.single_line_diagram_view.network_editable_area_size_changed"; },
        get EditBackgroundImage() { return "ihost.pages.single_line_diagram_view.edit_network_background_image"; },
        get MappingCultureSelected() { return "ihost.pages.single_line_diagram_view.mapping_culture_selected"; },
        get ImagerySetSelected() { return "ihost.pages.single_line_diagram_view.imagery_set_selected"; },
        get BackgroundOpacityChanged() { return "ihost.pages.single_line_diagram_view.background_opacity_changed"; }
    };

    public static CustomerFileManagement = {
        get UploadFile() { return "ihost.pages.customer_file_management.upload_file"; },
        get CopyFile() { return "ihost.pages.customer_file_management.copy_file"; },
        get DeleteFile() { return "ihost.pages.customer_file_management.delete_file"; },
        get DeleteFiles() { return "ihost.pages.customer_file_management.delete_files"; }
    }

    public static CustomerScheduleManagement = {
        get AddSchedule() { return "ihost.pages.customer_schedule_management.add_schedule"; },
        get UpdateSchedule() { return "ihost.pages.customer_schedule_management.update_schedule"; },
        get DeleteSchedule() { return "ihost.pages.customer_schedule_management.delete_schedule"; },
    }

    public static NetworkManagement = {
        get AddNetwork() { return "ihost.pages.network_management.add_network"; },
        get EditNetwork() { return "ihost.pages.network_management.edit_network"; },
        get UploadNetworkBackgroundImage() { return "ihost.pages.network_management.upload_network_background_image"; },
        get DeleteNetworkBackgroundImage() { return "ihost.pages.network_management.delete_network_background_image"; },
        get UploadNetwork() { return "ihost.pages.network_management.upload_network"; },
        get DeleteNetwork() { return "ihost.pages.network_management.delete_network"; }
    }

    public static ShapeLibrary = {
        get CreateShapeTemplate() { return "ihost.pages.shape_library.create_shape_template"; }
    }

    public static Trends = {
        get AddedTrend() { return "ihost.pages.trends.added_trend"; },
        get UpdatedTrend() { return "ihost.pages.trends.updated_trend"; },
        get LoadedTrend() { return "ihost.pages.trends.loaded_trend"; },
        get DeletedTrend() { return "ihost.pages.trends.deleted_trend"; },
        get RemovedTrend() { return "ihost.pages.trends.removed_trend"; },
        get Refreshed() { return "ihost.pages.trends.refreshed"; },
        get AddedLine() { return "ihost.pages.trends.added_line"; },
        get SavedLine() { return "ihost.pages.trends.saved_line"; },
        get RevertedLine() { return "ihost.pages.trends.reverted_line"; }
    }

    public static Html = {
        get MouseDown() { return "mousedown"; },
        get MouseMove() { return "mousemove"; },
        get MouseUp() { return "mouseup"; },
        get Resize() { return "resize"; }
    }

    public static Bootstrap = {
        get CloseAlert() { return "close.bs.alert"; },
        get ClosedAlert() { return "closed.bs.alert"; },
        get ShowModal() { return "show.bs.modal"; },
        get CloseModal() { return "hidden.bs.modal"; }
    }

    public static RtuGroupTree = {
        get RtuAdded() { return "ihost.pages.rtu_group_tree.rtu.added"; },
        get RtuUpdated() { return "ihost.pages.rtu_group_tree.rtu.updated"; },
        get RtuDeleted() { return "ihost.pages.rtu_group_tree.rtu.deleted"; },
        get RtuGroupAdded() { return "ihost.pages.rtu_group_tree.rtu_group.added"; },
        get RtuGroupDeleted() { return "ihost.pages.rtu_group_tree.rtu_group.deleted"; },
        get RtuGroupUpdated() { return "ihost.pages.rtu_group_tree.rtu_group.updated"; },
        get LocateRtu() { return "ihost.pages.rtu_group_tree.rtu.locate"; }
    }

    public static VirtualRtuGroupTree = {
        get VirtualRtuAdded() { return "ihost.pages.virtual_rtu_group_tree.rtu.added"; },
        get VirtualRtuUpdated() { return "ihost.pages.virtual_rtu_group_tree.rtu.updated"; },
        get VirtualRtuDeleted() { return "ihost.pages.virtual_rtu_group_tree.rtu.deleted"; },
        get ChannelAdded() { return "ihost.pages.virtual_rtu_group_tree.channel.added"; },
        get ChannelUpdated() { return "ihost.pages.virtual_rtu_group_tree.channel.updated"; },
        get ChannelDeleted() { return "ihost.pages.virtual_rtu_group_tree.channel.deleted"; }
    }

    public static SystemBackupRestore = {
        get DeleteBackup() { return "ihost.pages.system_backup_restore.delete_backup"; },
        get StartBackup() { return "ihost.pages.system_backup_restore.start_backup"; },
        get StartRestore() { return "ihost.pages.system_backup_restore.start_restore"; }
    }

    public static Compact = {
        get SideBarToggled() { return "ihost.pages.compact.sidebar.toggled"; }
    }

    public static FileInput = {
        get FileCleared() { return "filecleared"; },
        get FileLoaded() { return "fileloaded"; }
    }

    public static Select2 = {
        get Select() { return "select2:select"; },
        get Unselect() { return "select2:unselect"; },
        get Open() { return "select2:open"; },
        get Close() { return "select2:close"; }
    }

    public static RtuFirmwareUpload = {
        get UploadFirmware() { return "ihost.pages.rtu_firmware_upload.upload_firmware"; }
    }

    public static RtuConfigUpload = {
        get UploadConfig() { return "ihost.pages.rtu_config_upload.upload_config"; }
    }
}
