
import * as moment from 'moment-timezone';

export const enum DateOrder {
    DayMonthYear = 1,
    MonthDayYear = 2,
    YearMonthDay = 3
}

export const enum TimeOrder {
    HourMinuteSecond = 1
}

export interface LibraryDatePartsFormat {
    second: string;
    minute: string;
    hour: string;
    day: string;
    month: string;
    shortMonthName: string;
    year: string;
}

export interface LibraryDateFormat extends LibraryDatePartsFormat {
    dateTime: string;
    dateTimeHourMinute: string;

    date: string;
    dayMonth: string;
    monthYear: string
    shortMonthNameYear: string;

    time: string;
    hourMinute: string;
}

export interface DateSeparatorOrderFormat {
    dateOrder: DateOrder
    dateSeparator: string;
    timeOrder: TimeOrder
    timeSeparator: string;
}

export interface DateFormat extends DateSeparatorOrderFormat {
    moment: LibraryDateFormat;
    strf: LibraryDateFormat;
}

const dateFormatOptions: (DateSeparatorOrderFormat & { moment: LibraryDatePartsFormat; strf: LibraryDatePartsFormat })[] = [
    {
        moment: { second: "ss", minute: "mm", hour: "HH", day: "DD", month: "MM", shortMonthName: "MMM", year: "YY" },
        strf: { second: "%S", minute: "%M", hour: "%H", day: "%d", month: "%m", shortMonthName: "%b", year: "%y" },
        dateOrder: DateOrder.DayMonthYear,
        dateSeparator: "/",
        timeOrder: TimeOrder.HourMinuteSecond,
        timeSeparator: ":"
    },
    {
        moment: { second: "ss", minute: "mm", hour: "HH", day: "DD", month: "MM", shortMonthName: "MMM", year: "YY" },
        strf: { second: "%S", minute: "%M", hour: "%H", day: "%d", month: "%m", shortMonthName: "%b", year: "%y" },
        dateOrder: DateOrder.DayMonthYear,
        dateSeparator: ".",
        timeOrder: TimeOrder.HourMinuteSecond,
        timeSeparator: ":"
    },
    {
        moment: { second: "ss", minute: "mm", hour: "HH", day: "DD", month: "MM", shortMonthName: "MMM", year: "YY" },
        strf: { second: "%S", minute: "%M", hour: "%H", day: "%d", month: "%m", shortMonthName: "%b", year: "%y" },
        dateOrder: DateOrder.DayMonthYear,
        dateSeparator: "-",
        timeOrder: TimeOrder.HourMinuteSecond,
        timeSeparator: ":"
    },
    {
        moment: { second: "ss", minute: "mm", hour: "HH", day: "DD", month: "MM", shortMonthName: "MMM", year: "YYYY" },
        strf: { second: "%S", minute: "%M", hour: "%H", day: "%d", month: "%m", shortMonthName: "%b", year: "%Y" },
        dateOrder: DateOrder.DayMonthYear,
        dateSeparator: "/",
        timeOrder: TimeOrder.HourMinuteSecond,
        timeSeparator: ":"
    },
    {
        moment: { second: "ss", minute: "mm", hour: "HH", day: "DD", month: "MM", shortMonthName: "MMM", year: "YYYY" },
        strf: { second: "%S", minute: "%M", hour: "%H", day: "%d", month: "%m", shortMonthName: "%b", year: "%Y" },
        dateOrder: DateOrder.DayMonthYear,
        dateSeparator: ".",
        timeOrder: TimeOrder.HourMinuteSecond,
        timeSeparator: ":"
    },
    {
        moment: { second: "ss", minute: "mm", hour: "HH", day: "DD", month: "MM", shortMonthName: "MMM", year: "YYYY" },
        strf: { second: "%S", minute: "%M", hour: "%H", day: "%d", month: "%m", shortMonthName: "%b", year: "%Y" },
        dateOrder: DateOrder.DayMonthYear,
        dateSeparator: "-",
        timeOrder: TimeOrder.HourMinuteSecond,
        timeSeparator: ":"
    },
    {
        moment: { second: "ss", minute: "mm", hour: "HH", day: "DD", month: "MM", shortMonthName: "MMM", year: "YY" },
        strf: { second: "%S", minute: "%M", hour: "%H", day: "%d", month: "%m", shortMonthName: "%b", year: "%y" },
        dateOrder: DateOrder.MonthDayYear,
        dateSeparator: "/",
        timeOrder: TimeOrder.HourMinuteSecond,
        timeSeparator: ":"
    },
    {
        moment: { second: "ss", minute: "mm", hour: "HH", day: "DD", month: "MM", shortMonthName: "MMM", year: "YY" },
        strf: { second: "%S", minute: "%M", hour: "%H", day: "%d", month: "%m", shortMonthName: "%b", year: "%y" },
        dateOrder: DateOrder.MonthDayYear,
        dateSeparator: ".",
        timeOrder: TimeOrder.HourMinuteSecond,
        timeSeparator: ":"
    },
    {
        moment: { second: "ss", minute: "mm", hour: "HH", day: "DD", month: "MM", shortMonthName: "MMM", year: "YY" },
        strf: { second: "%S", minute: "%M", hour: "%H", day: "%d", month: "%m", shortMonthName: "%b", year: "%y" },
        dateOrder: DateOrder.MonthDayYear,
        dateSeparator: "-",
        timeOrder: TimeOrder.HourMinuteSecond,
        timeSeparator: ":"
    },
    {
        moment: { second: "ss", minute: "mm", hour: "HH", day: "DD", month: "MM", shortMonthName: "MMM", year: "YYYY" },
        strf: { second: "%S", minute: "%M", hour: "%H", day: "%d", month: "%m", shortMonthName: "%b", year: "%Y" },
        dateOrder: DateOrder.MonthDayYear,
        dateSeparator: "/",
        timeOrder: TimeOrder.HourMinuteSecond,
        timeSeparator: ":"
    },
    {
        moment: { second: "ss", minute: "mm", hour: "HH", day: "DD", month: "MM", shortMonthName: "MMM", year: "YYYY" },
        strf: { second: "%S", minute: "%M", hour: "%H", day: "%d", month: "%m", shortMonthName: "%b", year: "%Y" },
        dateOrder: DateOrder.MonthDayYear,
        dateSeparator: ".",
        timeOrder: TimeOrder.HourMinuteSecond,
        timeSeparator: ":"
    },
    {
        moment: { second: "ss", minute: "mm", hour: "HH", day: "DD", month: "MM", shortMonthName: "MMM", year: "YYYY" },
        strf: { second: "%S", minute: "%M", hour: "%H", day: "%d", month: "%m", shortMonthName: "%b", year: "%Y" },
        dateOrder: DateOrder.MonthDayYear,
        dateSeparator: "-",
        timeOrder: TimeOrder.HourMinuteSecond,
        timeSeparator: ":"
    },
    {
        moment: { second: "ss", minute: "mm", hour: "HH", day: "DD", month: "MM", shortMonthName: "MMM", year: "YYYY" },
        strf: { second: "%S", minute: "%M", hour: "%H", day: "%d", month: "%m", shortMonthName: "%b", year: "%Y" },
        dateOrder: DateOrder.YearMonthDay,
        dateSeparator: "-",
        timeOrder: TimeOrder.HourMinuteSecond,
        timeSeparator: ":"
    },
];

function createDateFormat(order: DateOrder, separator: string, dayFormat?: string, monthFormat?: string, yearFormat?: string): string {
    switch (order) {
        case DateOrder.DayMonthYear:
            return [
                ...(dayFormat != null ? [dayFormat] : []),
                ...(monthFormat != null ? [monthFormat] : []),
                ...(yearFormat != null ? [yearFormat] : [])
            ].join(separator);
        case DateOrder.MonthDayYear:
            return [
                ...(monthFormat != null ? [monthFormat] : []),
                ...(dayFormat != null ? [dayFormat] : []),
                ...(yearFormat != null ? [yearFormat] : [])
            ].join(separator);
        case DateOrder.YearMonthDay:
            return [
                ...(yearFormat != null ? [yearFormat] : []),
                ...(monthFormat != null ? [monthFormat] : []),
                ...(dayFormat != null ? [dayFormat] : []),
            ].join(separator);
        default:
            throw new Error("Unknown DateOrder.");
    }
}

function createTimeFormat(order: TimeOrder, separator: string, secondFormat?: string, minuteFormat?: string, hourFormat?: string): string {
    switch (order) {
        case TimeOrder.HourMinuteSecond:
            return [
                ...(hourFormat != null ? [hourFormat] : []),
                ...(minuteFormat != null ? [minuteFormat] : []),
                ...(secondFormat != null ? [secondFormat] : [])
            ].join(separator);
        default:
            throw new Error("Unknown TimeOrder.");
    }
}

function createLibraryDateFormat(separatorOrderFormat: DateSeparatorOrderFormat, partsFormat: LibraryDatePartsFormat): LibraryDateFormat {
    const dateFormat: string = createDateFormat(separatorOrderFormat.dateOrder, separatorOrderFormat.dateSeparator, partsFormat.day, partsFormat.month, partsFormat.year);
    const timeFormat: string = createTimeFormat(separatorOrderFormat.timeOrder, separatorOrderFormat.timeSeparator, partsFormat.second, partsFormat.minute, partsFormat.hour);
    return {
        dateTime: dateFormat + " " + timeFormat,
        dateTimeHourMinute: dateFormat + " " + createTimeFormat(separatorOrderFormat.timeOrder, separatorOrderFormat.timeSeparator, void 0, partsFormat.minute, partsFormat.hour),

        date: dateFormat,
        dayMonth: createDateFormat(separatorOrderFormat.dateOrder, separatorOrderFormat.dateSeparator, partsFormat.day, partsFormat.month, void 0),
        monthYear: createDateFormat(separatorOrderFormat.dateOrder, separatorOrderFormat.dateSeparator, void 0, partsFormat.month, partsFormat.year),
        shortMonthNameYear: createDateFormat(separatorOrderFormat.dateOrder, separatorOrderFormat.dateSeparator, void 0, partsFormat.shortMonthName, partsFormat.year),

        time: timeFormat,
        hourMinute: createTimeFormat(separatorOrderFormat.timeOrder, separatorOrderFormat.timeSeparator, void 0, partsFormat.minute, partsFormat.hour),

        second: partsFormat.second,
        minute: partsFormat.minute,
        hour: partsFormat.hour,
        day: partsFormat.day,
        month: partsFormat.month,
        shortMonthName: partsFormat.shortMonthName,
        year: partsFormat.year
    };
}

export default class DateTimeFormatter {
    private static defaultEndDuration: moment.Duration = moment.duration({
        hours: 23,
        minutes: 59,
        seconds: 59,
    });

    public static get defaultDateFormatIndex() {
        return 3;
    }

    public static get defaultDateFormatMsIndex() {
        return 3;
    }

    public static readonly dateFormats: DateFormat[] = dateFormatOptions.map(x => ({
        moment: createLibraryDateFormat(x, x.moment),
        strf: createLibraryDateFormat(x, x.strf),
        dateOrder: x.dateOrder,
        dateSeparator: x.dateSeparator,
        timeOrder: x.timeOrder,
        timeSeparator: x.timeSeparator
    }));

    public static getMomentDatesOrDefaults(dateFrom?: moment.Moment, dateTo?: moment.Moment) {
        if (!dateFrom) {
            dateFrom = moment.utc().startOf("day");
        }
        if (!dateTo) {
            dateTo = moment.utc(dateFrom).add(DateTimeFormatter.defaultEndDuration);
        }

        return {
            from: dateFrom,
            to: dateTo,
        };
    }

    /**
     * Gets a date time format object
     * @param dateFormatIndex The customer registry setting value
     * @returns date time format object
     */
    public static getFormatObject(dateFormatIndex: number): DateFormat {
        return DateTimeFormatter.dateFormats[dateFormatIndex]
            ? DateTimeFormatter.dateFormats[dateFormatIndex]
            : DateTimeFormatter.dateFormats[DateTimeFormatter.defaultDateFormatIndex];
    }

    /**
     * Gets a date time format
     * @param dateFormatIndex The customer registry setting value
     * @returns date time format
     */
    public static getFormat(dateFormatIndex: number): string {
        return DateTimeFormatter.getFormatObject(dateFormatIndex).moment.dateTime;
    }

    /**
     * Gets a date time format showing only day and month
     * @param dateFormatIndex The customer registry setting value
     * @returns date time format
     */
    public static getDayMonthFormat(dateFormatIndex: number): string {
        return DateTimeFormatter.getFormatObject(dateFormatIndex).moment.dayMonth;
    }

    /**
     * Gets a date time format showing only month and year
     * @param dateFormatIndex The customer registry setting value
     * @returns date time format
     */
    public static getMonthYearFormat(dateFormatIndex: number): string {
        return DateTimeFormatter.getFormatObject(dateFormatIndex).moment.monthYear;
    }

    /**
     * Gets a date time in strftime format
     * @param dateFormatIndex The customer registry setting value
     * @returns date time format
     */
    public static getStrfFormat(dateFormatIndex: number): string {
        return DateTimeFormatter.getFormatObject(dateFormatIndex).strf.dateTime;
    }

    /**
     * Gets a formatted date time.
     * @param date The Date to format
     * @param dateFormatIndex Index of date format.
     * @param includeTimezone Whether to include timezone in the formatted string. Default is false.
     * @param dateFormatMsIndex Index of milliseconds date format. Default is none.
     * @returns The date time in the relevant format
     */
    public static formatDateTimePortion(
        date: moment.Moment,
        dateFormatIndex: number,
        includeTimezone = false,
        dateFormatMsIndex = 0): string {
        if (date.valueOf() > 2147483647000) {
            // after 2038 there are no more DSTs transitions in the database, 
            // so for example Europe/London datetimes after 2038 will always be formatted as GMT +00:00.
            // this isn't necessary obvious to an end user, they may expect the speculated DST transitions to carry on forever,
            // for example a date in July which usually gets formatted in summer time, would start appearing 1 hourly earlier after 2038.
            // to remove any confusion, display dates after 2038 in UTC.
            date = date.clone().utc();
            includeTimezone = true;
        }
        const format = this.getFormat(dateFormatIndex);
        const msFormat = this.getMillisecondsFormat(dateFormatMsIndex);
        return date.format(`${format}${msFormat !== "" ? "." + msFormat : ""}${includeTimezone ? " z" : ""}`);
    }

    /**
     * gets a moment in the users timezone.
     * the original moment is unchanged
     */
    public static getUserTz(moment: moment.Moment, timeZone: string): moment.Moment {
        return moment.clone().tz(timeZone);
    }

    /**
     * Gets the milliseconds format for the given index.
     * @param dateFormatMsIndex Index of milliseconds date format.
     * @returns Milliseconds format
     */
    public static getMillisecondsFormat(dateFormatMsIndex: number): string {
        switch (dateFormatMsIndex) {
            case 0:
                return "";
            case 1:
                return "S";
            case 2:
                return "SS";
            default:
                return "SSS";
        }
    }

    /**
     * Gets a formatted date time which includes fractional seconds which has been converted to the given timezone.
     * @param utcDate The Date to format
     * @param timezone Timezone which should be used when formatting the date time.
     * @param dateFormatIndex Index of date format.
     * @param includeTimezone Whether to include timezone in the formatted string. Default is false.
     * @returns date time string
     */
    public static formatUtcTimeWithFractionalSeconds(
        utcDate: string,
        timezone: string,
        dateFormatIndex: number,
        includeTimezone = false): string {

        const splitTime = utcDate.split(".");
        let milliseconds = "";
        if (splitTime.length > 1) {
            utcDate = `${splitTime[0]}Z`;
            milliseconds = `.${splitTime[1].substr(0, splitTime[1].length - 1).padEnd(6, "0")}`;
        }
        const dateFormat = DateTimeFormatter.getFormat(dateFormatIndex);
        const momentInUserTz = DateTimeFormatter.getUserTz(moment(utcDate, "YYYY-MM-DDTHH:mm:ssZ"), timezone);
        let timezoneAbbr = "";
        if (includeTimezone) {
            timezoneAbbr = ` ${momentInUserTz.zoneAbbr()}`;
        }
        return `${momentInUserTz.format(dateFormat)}${(milliseconds)}${timezoneAbbr}`;
    }

    /**
     * Gets a formatted date time which includes fractional seconds which has been converted to UTC.
     * @param unixTime Date time as seconds since epoch.
     * @param timezone Timezone which should be used when converting unix timestamp in customer time to UTC.
     * @returns date time string
     */
    public static formatUnixCustomerTimeWithFractionalSecondsAsUtc(
        unixTime: number,
        timezone: string): string {

        const splitTime = unixTime.toString().split(".");
        let milliseconds = "";
        if (splitTime.length > 1) {
            unixTime = parseInt(splitTime[0]);
            milliseconds = `.${splitTime[1].padEnd(6, "0")}`;
        }
        const momentInUserTz = moment.tz(moment.unix(unixTime), timezone);
        return `${momentInUserTz.utc().format("YYYY-MM-DDTHH:mm:ss")}${milliseconds}Z`;
    }

    /**
     * Gets the seconds since epoch for given date time.
     * @param utcDate Date time in UTC.
     * @param timezone Timezone which should be used when calculating the seconds since epoch.
     * @returns seconds since epoch
     */
    public static getSecondsSinceEpoch(utcDate: string, timezone: string): number {
        const utcDateSplitTime = utcDate.split(".");
        let utcDateWithOutMs = utcDateSplitTime[0];
        let milliseconds = 0;
        if (utcDateSplitTime.length > 1) {
            utcDateWithOutMs = `${utcDateSplitTime[0]}Z`;
            milliseconds = parseFloat(`0.${(utcDateSplitTime[1].substr(0, utcDateSplitTime[1].length - 1))}`);
        }
        const utcDateInCustTz = DateTimeFormatter.getUserTz(moment(utcDateWithOutMs, "YYYY-MM-DDTHH:mm:ssZ"), timezone);
        return utcDateInCustTz.unix() + milliseconds;
    }

    /**
     * Format the current time as a UTC timestamp.
     * @param dateFormatMsIndex Index of milliseconds date format. Default is none.
     * @returns UTC date time string
     */
    public static utcNow(dateFormatMsIndex = 0): string {
        const msFormat = this.getMillisecondsFormat(dateFormatMsIndex);
        return moment.utc().format(`YYYY-MM-DDTHH:mm:ss${msFormat !== "" ? "." + msFormat : ""}[Z]`);
    }

    /**
     * Humanise duration for display.
     * @param duration The time to humanize as a moment duration.
     */
    public static humaniseDuration(duration: moment.Duration): string {
        // tslint:disable:object-literal-sort-keys
        const time: { [key: string]: number } = {
            year: Math.round(duration.years()),
            month: Math.round(duration.months()),
            day: Math.round(duration.days()),
            hour: Math.round(duration.hours()),
            minute: Math.round(duration.minutes()),
            second: Math.round(duration.seconds()),
        };
        // tslint:enable:object-literal-sort-keys

        const entries: string[] = [];
        for (let key in time) {
            if (time.hasOwnProperty(key)) {
                const value: number = time[key];
                if (value > 0) {
                    key = DateTimeFormatter.pluraliseText(key, value);
                    entries.push(`${value} ${key}`);
                }
            }
        }
        if (entries.length > 0) {
            const last = entries.splice(entries.length - 1, 1)[0];
            return entries.length > 0 ? `${entries.join(", ")} and ${last}` : last;
        }
        return "";
    }

    /**
     * Pluralises given text according to given value.
     * @param text Text to pluralise
     * @param value Value to determine if text should be pluralised
     */
    private static pluraliseText(text: string, value: number) {
        if (value > 1) {
            return `${text}s`;
        }
        return text;
    }

    /**
     * Adds st, nd, rd, or th to day of month.
     * @param dayOfMonth Day of month
     */
    public static getNthDayOfMonth(dayOfMonth: number): string {
        if (dayOfMonth > 3 && dayOfMonth < 21)
            return `${dayOfMonth}th`;

        switch (dayOfMonth % 10) {
            case 1:
                return `${dayOfMonth}st`;
            case 2:
                return `${dayOfMonth}nd`;
            case 3:
                return `${dayOfMonth}rd`;
            default:
                return `${dayOfMonth}th`;
        }
    }
}
