import * as ko from "knockout";
import LinksModel from "./LinksModel";
import { IHostApi } from "@iHost.WebApi/iHostWebApi";
import { UserRegistryKeys } from "@iHost.WebApi/iHostRegistryKeys";
import { User } from "@iHost.WebApi/iHostWebApiInterfaces.Customer";
import { HeaderViewModel } from "../Interfaces/HeaderModelInterface";

export default class HeaderModel implements HeaderViewModel {    
    public readonly customerLogo = ko.observable<string>(null);
    public readonly customerName = ko.observable<string>(null);
    public brandTitle = ko.observable<string>(null);
    public darkMode = ko.observable(false);
    public showDarkMode = ko.observable(false);
    public get additionalSelector() { return "#navbarHeaderAdditional"; }

    public headerLinks = new LinksModel("headerAdditional");
    public subHeaderLinks = new LinksModel("subHeaderAdditional");
    public tabsHeaderLinks = new LinksModel("tabsHeaderAdditional");

    private koSubscriptions: KnockoutSubscription[] = [];

    constructor(private datasource: IHostApi, private getCurrentUser: () => User) {
        this.addKoSubscriptions();
    }

    public dispose() {
        this.koSubscriptions.forEach(x => x.dispose());
    }

    private addKoSubscriptions(): void {
        this.koSubscriptions.push(this.darkMode.subscribe((newValue) => this.saveDarkMode(newValue)));
    }

    private saveDarkMode(darkMode: boolean): void {
        if (this.showDarkMode()) {
            const darkModeSetting = darkMode ? "1" : "0";
            const user = this.getCurrentUser();
            if (user) {
                user.UserSettings[UserRegistryKeys.DarkMode] = darkModeSetting;
                this.datasource.setCurrentUserSettings({ [UserRegistryKeys.DarkMode]: darkModeSetting });
            }
        }
    }
}
