import { IHostApi } from "@iHost.WebApi/iHostWebApi";
import { AuthError } from "@iHost.WebApi/iHostWebApiInterfaces.Auth";
import { AuthErrorCode } from "@iHost.WebApi/iHostWebApiInterfaces.Enums";
import { AuthLinks } from "@iHost.WebApi/iHostWebApiInterfaces.Links";
import { IHostApp } from "common/iHostCommonInterfaces";

export default class iHostCommonApp implements IHostApp {

    public usePluginArgsRedirect = false;

    constructor(protected datasource: IHostApi) { }

    async init(): Promise<any> {
        // on web api request error, if 401 or 403, with AuthLinks, then redirect to login screen
        $(document).ajaxError((event, jqXHR, ajaxSettings, thrownError) => {
            if (jqXHR.status === 401 || jqXHR.status === 403) {
                const authLinks: AuthLinks = jqXHR.responseJSON;
                if (authLinks && authLinks.WebLogin) {
                    // Use shortened url as redir parameter.
                    if (this.usePluginArgsRedirect) {
                        const args = encodeURIComponent(`${window.location.search}${window.location.hash}`);
                        window.top.location.href = `${authLinks.WebLogin}?plugin_args=${args}`;
                    } else {
                        const redir = encodeURIComponent(window.location.pathname + window.location.hash);
                        window.top.location.href = `${authLinks.WebLogin}?redir=${redir}`;
                    }
                }
            }
            else if (jqXHR.status === 503) {
                const authLinks: AuthError = jqXHR.responseJSON;
                if (authLinks && authLinks.ErrorCode === AuthErrorCode.LoginTemporarilyUnavailable) {
                    window.top.location.href = `${authLinks.WebLogin}?login_locked=1`;
                }
            }
        });
    }

    async refreshData(): Promise<any> { }
}
