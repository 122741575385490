import * as ko from "knockout";
import ViewLinkModel from "../Interfaces/ViewLinkModel";

export default class LinksModel {

    public position: KnockoutObservable<string>;
    public links: KnockoutObservableArray<ViewLinkModel>;

    public get additionalSelector() {
        return this.selector;
    }

    constructor(private selector: string, position: string = "navbar-left", links: ViewLinkModel[] = null) {
        this.position = ko.observable(position);
        this.links = ko.observableArray(links);
    }
}
