
import "core-js/stable";
import "regenerator-runtime/runtime";

// jquery must be loaded before bootstrap
import * as $ from 'jquery';
import "bootstrap";
import "jquery.scrollto";
import 'font-awesome/less/font-awesome.less';
import 'switch/styles.less';
import '@web-bootstrap-theme/bootstrap-nortech-theme.less';
import * as ko from "knockout";
import * as moment from "moment-timezone";
import iHostCommonApp from "../common/iHostCommonApp";
import DateTimeFormatter from "common/DateTimeFormatter";
import { IHostApi, default as iHostWebApi } from "@iHost.WebApi/iHostWebApi";
import { AppPlugin } from "@iHost.WebApi/iHostWebApiInterfaces.Plugins";
import { QueryStrings } from "@iHost.WebApi/iHostWebApiCommon";
import { CustomerRegistryKeys, UserRegistryKeys } from "@iHost.WebApi/iHostRegistryKeys";
import Header from "./Models/HeaderModel";
import Footer from "./Models/FooterModel";
import EventNames from "./Models/Events/EventNames";
import GlobalEvents from "./Models/Events/GlobalEvents";
import ErrorHandler from "common/ErrorHandler";
import { RefreshEventArgs, ViewShownArgs } from "./Models/Events/EventArgs";
import ViewArgs from "./Interfaces/ViewArgs";
import iHostPagesApp from "./Interfaces/iHostPagesApp";
import { User } from "@iHost.WebApi/iHostWebApiInterfaces.Customer";
import HeadViewModel from "./Models/HeadModel";
import { JQueryHelper } from "../common/CommonHelper";
import PageRouteArgs from "./Interfaces/PageRouteArgs"
import ShowConfirmEventArgs from "./Interfaces/ShowConfirmEventArgs"
import { ErrorArgs, IHostView, RefreshViewArgs, RouteDetails } from "common/iHostCommonInterfaces";
import PagesTemplate from "./Templates/PagesTemplate";
import ErrorObservables from "./Models/ErrorObservables";
import DynamicImportHelper from 'common/DynamicImportHelper';
import LazyStyle from './Interfaces/LazyStyle';
import LoginTemplate from 'app/Templates/Login/LoginTemplate';
import iHostPagesSettings from "./Interfaces/iHostPagesSettings";
import IHostWebPermissions from "@iHost.WebApi/iHostWebPermissions";

// Webpack DefinePlugin defines version
declare const PAGES_VERSION: string;

(async () => {
    window.appSettings = await $.ajax({
        dataType: "json",
        url: "appsettings.json"
      }) as iHostPagesSettings;

    const datasource = new iHostWebApi(window.appSettings.webApiUrlRoot, window.appSettings.webApiPageSizeLimit);
    
    const application = new PagesApp(datasource);

    await application.init();
})();

export default class PagesApp extends iHostCommonApp implements iHostPagesApp {

    private static get appId() {
        return "pagesapp";
    }

    private templateApp: PagesTemplate;

    private availableApps: AppPlugin[] = [];

    private views: IHostView[] = [];
    private currentView: IHostView;

    private confirmTimeout: number;
    private refreshIntervalId = 0;
    private readonly titlePrefix = "iHost";

    private currentError: ErrorArgs & ErrorObservables = {
        displayMessage: null,
        error: null,
        errorText: ko.observable<string | string[]>(null),
        isFatalError: ko.observable(false)
    };

    private headViewModel: HeadViewModel;

    // initial args
    public args: ViewArgs = {
        currentError: this.currentError,
        currentUser: {
            details: null,
            registrySettings: {
                autoRefresh: true,
                refreshTime: 60
            },
            permissions: null
        },
        customerRegistrySettings: {
            dateFormatIndex: DateTimeFormatter.defaultDateFormatIndex,
            dateFormatMsIndex: DateTimeFormatter.defaultDateFormatMsIndex
        },
        headerViewModel: new Header(this.datasource, (): User => { return this.args.currentUser.details; }),
        footerViewModel: new Footer(),
        routeTo: async (args: string[], title?: string, replace?: boolean, trailingSlash?: boolean) => this.routeTo(args, title, replace, trailingSlash),
        getCurrentRoute: () => this.getCurrentRoute(window.location.hash),
        getParameterByName: (name: string) => this.getParameterByName(name),
        refreshData: (args?: RefreshViewArgs) => this.refreshData(args),
        clearError: () => this.clearError(),
        clearWarning: () => this.clearWarning(),
        clearInfo: () => this.clearInfo(),
        setError: (err, errorObservable, errorSelector) => this.showError(err, errorObservable, errorSelector),
        setConfirmation: (msg, timeoutMs) => this.showConfirmation(msg, void 0, timeoutMs),
        setInfo: (msg) => this.showInfo(msg),
        setWarning: (msg) => this.showWarning(msg),
        updateLastUpdated: () => this.updateLastUpdated(),
        systemLinks: null,
        errorSelector: "#errorMsg",
        warningSelector: "#warningMsg",
        warningText: ko.observable<string>(null),
        confirmSelector: "#confirmMsg",
        confirmText: ko.observable<string>(null),
        infoSelector: "#infoMsg",
        infoText: ko.observable<string | string[]>(null),
        viewSelector: "#view",
        headerSelector: "#headerAdditional",
        subHeaderSelector: "#subHeaderAdditional",
        showView: ko.observable<boolean>(false),
        loadView: (viewName: string) => this.loadView(viewName),
        setPageTitle: (title?: string) => this.setPageTitle(title),
        setStandardRefresh: (refreshIntervalInSecs?: number) => {
            this.setAutoRefresh(refreshIntervalInSecs);
        },
        enableAutoRefresh: () => {
            // only enable if not already enabled
            if (this.args.currentUser.registrySettings.autoRefresh && this.refreshIntervalId < 1) {
                this.setAutoRefresh();
            }
        },
        disableAutoRefresh: () => {
            this.clearAutoRefresh();
        },
        formatTimestamp: (timestamp: string, includeMs?: boolean, includeTimezone?: boolean, asUtc?: boolean) => this.formatTimestamp(timestamp, includeMs, includeTimezone, asUtc),
        getNowTimestamp: (includeMs?: boolean, includeTimezone?: boolean) => this.getNowTimestamp(includeMs, includeTimezone),
        getEpoch: (timestamp: string) => this.getEpoch(timestamp)
    };

    constructor(datasource: IHostApi) {
        super(datasource);

        this.headViewModel = new HeadViewModel(this.args.headerViewModel.darkMode);
        
        // declare globally, loadLegacyIFrame set later
        window.iHost = { }; 

        window.onhashchange = () => {
            this.route(document.location.hash);
        };  
        // these are to show and hide the sub-header when 
        // the menu button is show/hidden
        $("#sub-header").on("show.bs.collapse", function (this: HTMLElement) {
            $(this).parent("nav").removeClass("hidden-xs");
        });
        $("#sub-header").on("hidden.bs.collapse", function (this: HTMLElement) {
            $(this).parent("nav").addClass("hidden-xs");
        });    
        
        // expose these to allow external comms
        document.viewArgs = this.args;
    }

    async init() {
        try {
            GlobalEvents.refreshing();
            
            const templateName = QueryStrings.getQueryStringVal("view");
            window.appSettings.isCompact = templateName === 'compact';
            await this.initDarkMode();
            await this.initTemplate(templateName);
            this.initAlertMessages();

            // fix to autofocus select2 input search box on open due to issue with jQuery 3.5.2+
            $(document).on(EventNames.Select2.Open, () => {
                (document.querySelector(".select2-container--open .select2-search__field") as HTMLElement).focus();
            });

            if (templateName !== "login") {
                this.initRefreshHandlers();
                await super.init();
                await this.loadInitialData();
                this.initHeader();
                this.initFooter();
            }

            ko.applyBindings(this.headViewModel, $("head").get(0));
            ko.applyBindings(this.args.showView, $(this.args.viewSelector).parent().get(0));
            await this.route(document.location.hash);
        }
        catch (err) {
            this.showError(err, void 0, void 0, true);
            throw err;
        }
        finally {
            GlobalEvents.refreshing(false);
            this.initLinks();
        }
    }

    private initRefreshHandlers(): void {
        // on refresh then toggle loading icons
        $(document).on(EventNames.Refresh, (evt: JQuery.TriggeredEvent, args: RefreshEventArgs) => {
            console.debug(`loading-${args.refreshing}`);
            $(".loading-icon").toggle(args.refreshing);
        });

        document.refreshHandler = () => {
            // if fatal error then do a force reload
            if (this.args.currentError.isFatalError()) {
                document.location.reload();
            } else {
                this.refreshData({ userInitiated: true });
            }
        };

        document.autoRefreshChangedHandler = (autoRefreshOn: boolean) => {
            this.clearAutoRefresh();
            this.args.currentUser.registrySettings.autoRefresh = autoRefreshOn;
            if (autoRefreshOn) {
                const autoRefreshDisabled = this.currentView && this.currentView.autoRefreshDisabled;
                if (autoRefreshDisabled) {
                    this.args.refreshData();
                } else {
                    this.args.enableAutoRefresh();
                }
            }
        };
    }

    private initHeader(): void {
        $("#header").show();
        ko.applyBindings(this.args.headerViewModel, $("#header").get(0));
    }

    private initFooter(): void {
        $("footer").show();
        if ($("footer").length === 1) {
            ko.applyBindings(this.args.footerViewModel, $("footer").get(0));
        }
    }

    private initAlertMessages(): void {
        $(this.args.infoSelector).on(EventNames.Bootstrap.CloseAlert, () => {
            this.clearInfo();
            return false;
        });
        $(this.args.errorSelector).on(EventNames.Bootstrap.CloseAlert, () => {
            this.clearError();
            return false;
        });
        $(this.args.warningSelector).on(EventNames.Bootstrap.CloseAlert, () => {
            this.clearWarning();
            return false;
        });
        $(this.args.confirmSelector).on(EventNames.Bootstrap.CloseAlert, () => {
            this.clearConfirmTimeout();
            this.args.confirmText(null);
            return false;
        });
        $(this.args.confirmSelector).on(EventNames.ShowConfirm, (event: any, eventArgs: ShowConfirmEventArgs) => {
            this.clearConfirmTimeout();
            if (!eventArgs || !eventArgs.cancelClearTimeout) {
                this.confirmTimeout = window.setTimeout(() => $(this.args.confirmSelector).alert('close'), eventArgs.timeoutInMs ? eventArgs.timeoutInMs : 2500);
            }
            return false;
        });
        ko.applyBindings(this.args.currentError, $(this.args.errorSelector).get(0));
        ko.applyBindings(this.args.warningText, $(this.args.warningSelector).get(0));
        ko.applyBindings(this.args.confirmText, $(this.args.confirmSelector).get(0));
        ko.applyBindings(this.args.infoText, $(this.args.infoSelector).get(0));
    }

    private async initTemplate(templateName: string) {
        if (templateName === 'login') {
            const [template, css, templateCtor] = await Promise.all([
                DynamicImportHelper.getDefault<string>(await import("./Templates/Login/Html/Template.html")),
                DynamicImportHelper.getDefault<LazyStyle>(await import("./Templates/Login/css/styles.less?lazy")),
                DynamicImportHelper.getDefault<(datasource: IHostApi, args: ViewArgs) => void>(await import("app/Templates/Login/LoginTemplate"))
            ]);

            css.use();
            $(document.body).html(template);
            this.templateApp = new templateCtor(this.datasource, this.args) as LoginTemplate;
            await this.templateApp.init();
        }
        else if (templateName === 'compact') {
            const CompactCss = DynamicImportHelper.getDefault<LazyStyle>(await import(
                "./Templates/Compact/css/compact-view.less?lazy"));
            CompactCss.use();

            const CompactHtml = DynamicImportHelper.getDefault<string>(await import(
                "./Templates/Compact/Html/Template.html"));
            $(document.body).html(CompactHtml);

            const templateCtor =
                // note splitting into a separate chunk by using lazy mode currently
                // doesn't work as we're using module system AMD see https://github.com/webpack/webpack/issues/5703
                DynamicImportHelper.getDefault<(datasource: IHostApi, args: ViewArgs) => void>(await import(
                    "app/Templates/Compact/Template"));

            const template: PagesTemplate = new templateCtor(this.datasource, this.args);
            this.templateApp = template;
            this.usePluginArgsRedirect = template.usePluginArgsRedirect;
            // init the template first, may want to overwrite stuff
            await this.templateApp.init();
        }
        else {
            const PagesCss = DynamicImportHelper.getDefault<LazyStyle>(await import(
                "./Templates/Pages/css/pages.less?lazy"));
            PagesCss.use();

            const PagesHtml = DynamicImportHelper.getDefault<string>(await import(
                "./Templates/Pages/Html/Template.html") );
            $(document.body).html(PagesHtml);
        }
    }

    async refreshData(args: RefreshViewArgs = {}) {
        const route = this.getCurrentRoute(document.location.hash);
        args.args = route.args;
        GlobalEvents.refreshing();
        this.clearFatalError();
        try {
            // update the current template
            if (this.templateApp) {
                await this.templateApp.refreshData(args);
            }
            // update the current view
            await this.currentView.refreshData(args);
            // when all done update the lastupdated value
            this.updateLastUpdated();
        }
        catch (err) {
            this.showError(err);
            throw err;
        }
        finally {
            GlobalEvents.refreshing(false);
        }
    }

    private setAutoRefresh(refreshIntervalInSecs?: number): void {
        this.clearAutoRefresh();
        if (refreshIntervalInSecs === void 0) {
            refreshIntervalInSecs = this.args.currentUser.registrySettings.autoRefresh
                ? this.args.currentUser.registrySettings.refreshTime
                : 0;
        }
        if (refreshIntervalInSecs > 0) {
            this.refreshIntervalId = window.setInterval(() => {
                if (this.args.currentError.isFatalError()) {
                    window.clearInterval(this.refreshIntervalId);
                    this.refreshIntervalId = 0;
                    console.log("Cancelled auto refresh due to fatal error");
                } else {
                    this.args.refreshData();
                }
            }, refreshIntervalInSecs * 1000);
        }
    }

    private clearAutoRefresh(): void {
        if (this.refreshIntervalId > 0) {
            window.clearInterval(this.refreshIntervalId);
            this.refreshIntervalId = 0;
        }
    }

    private updateLastUpdated() {
        this.args.footerViewModel.lastUpdated(this.getNowTimestamp(false, true));
    }

    private clearConfirmTimeout() {
        if (this.confirmTimeout) {
            window.clearTimeout(this.confirmTimeout);
            this.confirmTimeout = null;
        }
    }

    private clearFatalError() {
        if (this.args.currentError.isFatalError()) {
            this.currentError.errorText(null);
        }
    }

    private clearError() {
        if (!this.args.currentError.isFatalError()) {
            this.currentError.errorText(null);
        }
    }

    private clearWarning() {
        this.args.warningText(null);
    }

    private clearInfo() {
        this.args.infoText(null);
    }

    private showError(err: ErrorArgs | JQueryXHR | Error, errorObservable?: KnockoutObservable<string | string[]>, errorSelector?: string, suppressConsoleLog = false) {
        if (err) {
            if (JQueryHelper.isXHR(err)) {
                // Bad Request (ModelState)
                if (err.responseJSON?.ModelState && err.status === 400) {
                    const messages = [];
                    for (const field of Object.keys(err.responseJSON.ModelState)) {
                        messages.push(...err.responseJSON.ModelState[field]);
                    }
                    this.currentError.displayMessages = messages;
                    // Bad Request (Message) / Forbidden / Not Found / Conflict 
                } else if (err.responseJSON?.Message && (err.status === 400 || err.status === 403 || err.status === 404 || err.status === 409)) {
                    this.currentError.displayMessage = err.responseJSON.Message;
                } else {
                    this.currentError.displayMessage = `Unexpected error. Please try again.`;
                }
            } else if (err instanceof Error) {
                this.currentError.isFatalError(true);
                this.currentError.error = err;
            } else {
                if (err?.isFatal) {
                    this.currentError.isFatalError(true);
                }
                if (err?.displayMessage) {
                    this.currentError.displayMessage = err.displayMessage;
                }
            }
            if (console?.error && !suppressConsoleLog) {
                console.error(err);
            }
        }

        $.scrollTo(errorSelector || this.args.errorSelector);
        ErrorHandler.logError(this.currentError, errorObservable || this.currentError.errorText, EventNames.ShowError);
    }

    private showConfirmation(msg = "Saved changes.", cancelClearTimeout = false, timeoutInMs = 4000): void {
        this.args.confirmText(msg);
        $.scrollTo(this.args.confirmSelector);
        $(this.args.confirmSelector).trigger(EventNames.ShowConfirm, { cancelClearTimeout, timeoutInMs });
    }

    private showInfo(msg): void {
        this.args.infoText(msg);
        $.scrollTo(this.args.infoSelector);
        $(this.args.infoSelector).trigger(EventNames.ShowInfo);
    }

    private showWarning(msg): void {
        this.args.warningText(msg);
        $.scrollTo(this.args.warningSelector);
        $(this.args.warningSelector).trigger(EventNames.ShowWarning);
    }

    /**
     * loads initial stuff that may be useful for all views
     */
    private async loadInitialData(): Promise<void> {
        const [user, systemLinks, systemStatus] = await Promise.all([
            this.getCurrentUser(),
            this.datasource.getSystemLinks(),
            this.datasource.getSystemStatus()]);

        this.args.currentUser.details = user;
        this.args.currentUser.permissions = user.AssignedPermissions;
        this.args.customerRegistrySettings.dateFormatIndex = +user.CustomerSettings[CustomerRegistryKeys.DateFormat];
        this.args.customerRegistrySettings.dateFormatMsIndex = +user.CustomerSettings[CustomerRegistryKeys.DateFormatMs];
        this.args.currentUser.registrySettings.autoRefresh = user.UserSettings[UserRegistryKeys.AutoRefresh] === "1";
        this.args.currentUser.registrySettings.refreshTime = +user.UserSettings[UserRegistryKeys.RefreshTime];
        
        this.args.footerViewModel.serverName(systemStatus.ServerName);
        this.args.footerViewModel.version(PAGES_VERSION);
        this.args.systemLinks = systemLinks;

        this.clearError();
        this.args.confirmText(null);

        moment.tz.setDefault(this.args.currentUser.details.PrimaryCustomer.TimeZone);

        if (this.templateApp) {
            await this.templateApp.loadInitialData();
        }
        this.updateLastUpdated();
    }

    private async getCurrentUser(): Promise<User> {
        const user = await this.datasource.getCurrentUser({ includeApps: false }, true, true, true, true);
        if (IHostWebPermissions.hasPermission(user.AssignedPermissions, IHostWebPermissions.ViewUserApps)) {
            user.Apps = await this.datasource.getCurrentUserApps();
        }
        return user;
    }

    private async initDarkMode() {
        // initially try to get away with loading only one of the bootstrap less files
        // when initialising for better performance
        if (this.args.headerViewModel.darkMode()) {
            const bootstrapDark = DynamicImportHelper.getDefault<LazyStyle>(await import(
                "bootswatch/darkly-nml.less?lazy"));
            bootstrapDark.use();
        }
        else {
            const bootstrapLight = DynamicImportHelper.getDefault<LazyStyle>(await import(
                "bootstrap/less/bootstrap.less?lazy"));
            bootstrapLight.use();
        }

        this.args.headerViewModel.darkMode.subscribe(async (darkMode) => {
            // if the darkMode has changed sine initialising then we must load both less files
            const bootstrapLight = DynamicImportHelper.getDefault<LazyStyle>(await import(
                "bootstrap/less/bootstrap.less?lazy"));
            const bootstrapDark = DynamicImportHelper.getDefault<LazyStyle>(await import(
                "bootswatch/darkly-nml.less?lazy"));

            if (darkMode) {
                bootstrapLight.unuse();
                bootstrapDark.use();
            }
            else {
                bootstrapDark.unuse();
                bootstrapLight.use();
            }
        });
    }

    private initLinks() {
        const self = this;

        // for all links with pushstate
        $("body").on("click", "a[data-pushstate]", function (this: HTMLElement) {
            const pushState = $(this).data("pushstate");
            if (pushState && pushState.length > 0) {
                self.navigateTo({
                    url: pushState,
                    title: $(this).text()
                });
                return false;
            }
            return true;
        });

        // update all a tags that should toggle a collapsable element
        $("body").on("click", "a[data-toggle][data-target]", function (this: HTMLElement) {
            // get target
            const el = $($(this).data("target"));
            // only hide it if it is collapsed and open
            if (el.is(".collapse.in"))
                el.collapse("hide");
        });

        $("ul.dropdown-menu.checkbox-menu").on("click", function (event: JQuery.TriggeredEvent) {
            event.stopPropagation();
        });
    }

    /**
     * Switches to a view
     * @param url Parsed URL to establish view name
     * @param showView Show the view if previously not active
     * @param reload Triggered by reloading the page
     */
    public async route(url: string, showView = true, reload = false) {
        if (this.args.currentError.isFatalError())
            throw undefined;

        // display different page
        // this should load the page via ajax and pass the arguments in
        const hashUrl = url.substring(url.indexOf("#"));
        const viewDetails = this.getCurrentRoute(hashUrl);

        // Check if we need to go fullscreen.
        if (window.top.location.href !== window.location.href && QueryStrings.hasQueryStringKey("fullscreen", false, viewDetails.query)) {
            window.top.location.href = window.location.href;
            return;
        }

        // try to get view from cache
        const view = this.views[viewDetails.view];

        // view is already in cache
        if (view) {
            // view is active
            if (view == this.currentView) {
                GlobalEvents.refreshing();
                try {
                    const result = await this.currentView.updateView(viewDetails.args);
                    if (!result || !result.skipRefresh) {
                        return await this.refreshData();
                    }
                }
                catch (err) {
                    this.showError(err);
                    if (this.currentError.isFatalError()) {
                        this.args.showView(false);
                    }

                    throw err;
                }
                finally {
                    GlobalEvents.refreshing(false);
                }
            }
            // switch to a different view
            else {
                if (this.currentView) {
                    this.currentView.unloadView();
                }
                this.currentView = view;
                if (showView) {
                    GlobalEvents.refreshing();
                    try {
                        $(document).trigger(EventNames.ViewShown, <ViewShownArgs>{
                            viewName: this.currentView.name
                        });
                        const result = await this.currentView.showView(viewDetails.args);
                        if (!result || !result.skipRefresh) {
                            return await this.refreshData();
                        }
                    }
                    catch (err) {
                        this.showError(err);
                        throw err;
                    }
                    finally {
                        GlobalEvents.refreshing(false);
                    }
                }
            }
        } else {
            if (!reload) {
                // try to load view via require then recursively callback
                // should be refactored to reuse the loadView function
                if (!view) {
                    await this.loadView(viewDetails.view);
                    await this.route(hashUrl, showView, true);
                }

            } else {
                throw <ErrorArgs>{
                    logMessage: `Could not find view "${viewDetails.view}"`,
                    isFatal: true
                };
            }
        }
    }

    /**
     * Fetches a view
     * @param viewName the name of the view to load
     * @returns IHostView The view
     */
    private async loadView<T extends IHostView>(viewName: string): Promise<T & IHostView> {
        try {
            let view: T = this.views[viewName];
            if (view) {
                return view;
            }
            const pageViewCtor =
                // note splitting into a separate chunk by using lazy mode currently
                // doesn't work as we're using module system AMD see https://github.com/webpack/webpack/issues/5703
                DynamicImportHelper.getDefault<(datasource: IHostApi, args: ViewArgs) => void>(await import(
                    /* webpackInclude: /\w+/ */
                    /* webpackChunkName: "[request][index]" */
                    `./Views/${viewName}/PageView`));
            view = new pageViewCtor(this.datasource, this.args);
            this.views[view.name] = view;
            return view;
        }
        catch (err) {
            throw <ErrorArgs>{ logMessage: err, isFatal: true };
        }
    }

    /**
     * Converts a url into view details
     * @param url the url
     * @returns ViewDetails The View
     */
    private getCurrentRoute(url: string): RouteDetails {
        const viewIndex = url.indexOf("/");
        const viewName = url.substring(2, viewIndex);
        const queryIndex = url.indexOf("?");
        // +1 to get rid of the /
        const viewQuery = queryIndex !== -1 ? url.substring(queryIndex + 1) : "";
        const viewArgs = url.substring(viewIndex + 1);

        return {
            view: viewName,
            args: viewArgs,
            query: viewQuery
        };
    }

    /**
     * Navigates to the view
     * Attempts to use window.history but falls back to simulating a users click
     * @param args Navigation options
     * @returns Promise<void> that resolves on done
     */
    public navigateTo(args: PageRouteArgs) {
        // use history api to add browser back/fwd links correctly
        if (window.history && window.history.pushState) {
            if (args.title) {
                this.args.setPageTitle(args.title);
            }
            if (args.replace) {
                history.replaceState(args, args.title, args.url);
            } else {
                history.pushState(args, args.title, args.url);
                return this.route(args.url);
            }
        } else {
            // just set the href, this url will be the hash
            // and will cause the hashchange event to fire
            document.location.href = args.url;
        }
        return $.Deferred().resolve();
    }

    /**
     * Implodes the url args using / to form a URL then navigates to it
     * @param args URL Arguments
     * @param title Page title
     * @param replace To replace or to push to the hashed state
     * @param trailingSlash Append trailing slash to URL
     * @returns Promise that resolves on done
     */
    public routeTo(args: string[], title?: string, replace?: boolean, trailingSlash = true) {
        let url = `#!${args.join("/")}`;
        if (trailingSlash) {
            url += "/";
        }

        return this.navigateTo({
            url: url,
            initialArgs: args,
            title: title,
            replace: replace
        });
    }

    /**
     * Sets the document title by appending the title to the PageApp prefix
     * If a templateApp is present it's prefix is used instead
     * @param Appended to prefix if present
     */
    private setPageTitle(title?: string): void {
        const prefix = this.templateApp && this.templateApp.titlePrefix ? this.templateApp.titlePrefix : this.titlePrefix;
        this.headViewModel.title(title ? `${prefix}: ${title}` : prefix)
    }

    /**
     * Gets a query parameter by name from the hashed state.
     * @param name string parameter name.
     */
    private getParameterByName(name: string): string {
        const url = window.location.hash;
        name = name.replace(/[\[\]]/g, "\\$&");
        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, "%20"));
    }

    private formatTimestamp(timestamp: string, includeMs?: boolean, includeTimezone?: boolean, asUtc?: boolean): string {
        return this.formatMoment(asUtc ? moment.utc(timestamp) : moment(timestamp), includeMs, includeTimezone);
    }

    private formatMoment(moment: moment.Moment, includeMs?: boolean, includeTimezone?: boolean): string {
        return this.args.customerRegistrySettings
            ? DateTimeFormatter.formatDateTimePortion(
                moment,
                this.args.customerRegistrySettings.dateFormatIndex,
                includeTimezone,
                includeMs ? this.args.customerRegistrySettings.dateFormatMsIndex : 0)
            : "";
    }

    private getEpoch(timestamp: string): number {
        return moment(timestamp).unix();
    }

    private getNowTimestamp(includeMs?: boolean, includeTimezone?: boolean): string {
        return this.formatMoment(moment(), includeMs, includeTimezone);
    }
}