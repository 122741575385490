import * as $ from "jquery";
import * as ko from "knockout";

import EventNames from "./EventNames";
import { RefreshEventArgs } from "./EventArgs";

export default class GlobalEvents {
    public static loading = ko.observable(true);

    public static refreshing(refreshing = true) {
        this.loading(refreshing);
        $(document).trigger(EventNames.Refresh, <RefreshEventArgs>{ refreshing: refreshing });
    }
}

