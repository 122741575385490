import * as $ from "jquery";
import { ErrorArgs } from "./iHostCommonInterfaces";

export default class ErrorHandler {

    private static readonly DefaultErrMsg = "An error occurred loading data. Please refresh the page";

    /**
     * logs the error message to the console and UI
     * sets the logMessage, displayMessage and displayMessage properties to null once complete
     */
    public static logError(
        err: ErrorArgs,
        observable?: KnockoutObservable<string | string[]>,
        eventName: string | null = null,
        suppressConsoleLog = false) {

        if (err && !suppressConsoleLog) {
            if (console.error) {
                if (err.logMessage) {
                    console.error(err.logMessage);
                }
                if (err.error) {
                    console.error(err.error);
                }
            }
        }

        if (observable) {
            if (err && err.displayMessage) {
                observable(err.displayMessage);
            }
            else if (err && err.displayMessages) {
                observable(err.displayMessages);
            }
            else {
                observable(ErrorHandler.DefaultErrMsg);
            }
        }

        if (err) {
            // reset error details
            err.logMessage = null;
            err.displayMessage = null;
            err.displayMessages = null;
            err.error = null;
        }

        if (eventName) {
            $(err).trigger(eventName);
        }
    }

    /**
     * logs the XHR error message when status code is:
     *  400 (Bad Request)
     *  404 (Not Found)
     *  409 (Conflict)
     * to the console and UI.
     */
    public static logXHRError(
        jqXHR: JQueryXHR,
        displayMessage: string,
        observable?: KnockoutObservable<string | string[]>,
        eventName: string | null = null) {

        switch (jqXHR.status) {
            case 400:
            case 409:
                if (jqXHR.responseJSON && jqXHR.responseJSON.Message) {
                    this.logError({ displayMessage: jqXHR.responseJSON.Message }, observable, eventName);
                    return;
                }
                break;

            case 404:
                this.logError({ displayMessage: `${displayMessage}, as no longer exists.` }, observable, eventName);
                return;
        }
        this.logError({ displayMessage: `${displayMessage}.` }, observable, eventName);
    }
}
